import "./privacy-policy.scss"

import { graphql } from "gatsby"
import * as React from "react"
import { Container } from "react-bootstrap"
import { useTranslation } from "gatsby-plugin-react-i18next"
import FindDreamJob from "../components/careers/finddreamjob"
import Layout from "../components/layout/layout"
import Seo from "../components/seo"
import "./mainpage.scss"

const IndexPage = () => {
  const { t, i18n } = useTranslation("privacy")
  const pageTitle = t("title") 
  const pageDescription = "BTS privacy"
  const meta = []
  const breadcrumbMap = [{ title: "Privacy", url: "#" }]
  return (
    <>
      <Layout breadcrumbMap={breadcrumbMap} lang={i18n.resolvedLanguage}>
        <div className="max-container-pages">
          <Seo
            title={pageTitle}
            description={pageDescription}
            lang={i18n.resolvedLanguage}
            meta={meta}
          />
          <Container fluid="xl">
            <section className="section-privacy">
              <HeaderSection t={t} />
              <InformationCollection t={t} />
              <CookiesSection t={t} />
              <InformationUse t={t} />
              <InformationSharing t={t} />
              <AccessInformation t={t} />
              <TrackingSection t={t} />
              <SecuritySection t={t} />
              <BeyondControl t={t} />
              <ContactSection t={t} />
            </section>
          </Container>
          <FindDreamJob></FindDreamJob>
        </div>
      </Layout>
    </>
  )
}

export const HeaderSection = ({ t }) => {
  return (
    <section className="header mt-1">
      <h1>{t("headerTitle.title")}</h1>
      <div className="mt-2">{t("headerTitle.effectiveDate")}</div>
      <div className="mt-4">{t("headerTitle.introduction")}</div>
    </section>
  )
}

export const InformationCollection = ({ t }) => {
  return (
    <section className="information mt-4">
      <span>{t("informationCollection.title")}</span>
      <div className="mt-1">{t("informationCollection.content")}</div>
    </section>
  )
}

export const CookiesSection = ({ t }) => {
  return (
    <section className="cookies mt-4">
      <span>{t("cookies.title")}</span>
      <br />
      <div>{t("cookies.mainText")}</div>
      <div>{t("cookies.cookiesInfo")}</div>
      <div>{t("cookies.additionalInfo")}</div>
    </section>
  )
}

export const InformationUse = ({ t }) => {
  return (
    <section className="information-use mt-4">
      <span>{t("informationUse.title")}</span>
      <br />
      <div>{t("informationUse.personalInfo")}</div>
      <div>{t("informationUse.anonymousInfo")}</div>
    </section>
  )
}

export const InformationSharing = ({ t }) => {
  return (
    <section className="information-sharing mt-4">
      <span>{t("informationSharing.title")}</span>
      <br />
      <div>{t("informationSharing.content")}</div>
    </section>
  )
}

export const AccessInformation = ({ t }) => {
  return (
    <section className="access-information mt-4">
      <span>{t("accessToInformation.title")}</span>
      <br />
      {t("accessToInformation.content")}
    </section>
  )
}

export const TrackingSection = ({ t }) => {
  return (
    <section className="tracking-section mt-4">
      <span>{t("tracking.title")}</span>
      <br />
      <div>{t("tracking.browsers")}</div>
      <div>{t("tracking.california")}</div>
      <div>{t("tracking.children")}</div>
    </section>
  )
}

export const SecuritySection = ({ t }) => {
  return (
    <section className="security mt-4">
      <span>{t("security.title")}</span>
      <br />
      <div>{t("security.content")}</div>
    </section>
  )
}

export const BeyondControl = ({ t }) => {
  return (
    <section className="beyond-control mt-4">
      <span>{t("beyondControl.title")}</span>
      <br />
      <div>{t("beyondControl.forums")}</div>
      <div>{t("beyondControl.thirdParty")}</div>
    </section>
  )
}

export const ContactSection = ({ t }) => {
  return (
    <section className="contact mt-4">
      <span>{t("contact.title")}</span>
      <br />
      <div>{t("contact.contactInfo")}</div>
      <div>{t("contact.updates")}</div>
    </section>
  )
}

export default IndexPage
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common", "privacy"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
